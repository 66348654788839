import request from '@/service/lib/request';

export function getList(data) {
  return request({
    url: '/articlecategory/categories',
    method: 'get',
    params: data
  });
}
export function saveModel(data) {
  return request({
    url: '/articlecategory/category',
    method: 'post',
    data
  });
}
export function getModel(id) {
  return request({
    url: '/articlecategory/category/' + id,
    method: 'get'
  });
}
export function deleteModel(id) {
  return request({
    url: '/articlecategory/category/' + id,
    method: 'delete'
  });
}

export function getComboBox(data) {
  return request({
    url: '/articlecategory/category_combobox',
    method: 'get',
    params: data
  });
}
