import request from '@/service/lib/request';

export function getList (data) {
  return request({
    url: '/article/products',
    method: 'get',
    params: data
  });
}
export function getModel (id) {
  return request({
    url: '/article/product/' + id,
    method: 'get'
  });
}
export function saveModel (data) {
  return request({
    url: '/article/product',
    method: 'post',
    data
  });
}
export function deleteModel (id) {
  return request({
    url: '/article/product/' + id,
    method: 'delete'
  });
}